import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import "./video-modal.css";

/* Usage –
  Must include the ariaHideApp prop to prevent render order issues. The caption
  prop can handle HTML. Always needs a posterImage and videoPlayIcon, which can
  be different each time. The variant lets you pass a variant class for the
  image container, so that different aspect ratios and sizes are possible.
  Passing a variant class requires creating that variant class's styles.

  <VideoModal
    ariaHideApp={false}
    caption={[<span>Watch now</span>]}
    posterImage={classImg}
    videoPlayIcon={playIcon}
    videoSource="//www.youtube.com/embed/n0_Hw4RNXrI"
    variant={"variant-class"}
  />
*/

const VideoModal = ({
  allow,
  caption,
  height,
  posterImage,
  variant,
  videoPlayIcon,
  videoSource,
  width
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function handleKeydown(e) {
    if (e.key !== "Tab") {
      openModal();
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className={`video-modal ${variant}`}>
      <div className="video-modal__image">
        <img src={posterImage} alt="Video" />
        <div
          className="video-modal__play-icon"
          onClick={openModal}
          onKeyDown={handleKeydown}
          role="button"
          tabIndex="0"
        >
          <img src={videoPlayIcon} alt="Play Video" />
        </div>
      </div>

      {!!caption && (
        <div
          className="video-modal__caption"
          onClick={openModal}
          onKeyDown={handleKeydown}
          role="button"
          tabIndex="0"
        >
          {caption}
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        style={customModalStyles}
      >
        <button className="modal-close-btn" onClick={closeModal}>
          <span></span>
          <span></span>
        </button>
        <iframe
          src={videoSource}
          title="Video"
          width={width}
          height={height}
          frameborder="0"
          scrolling="auto"
          loading="lazy"
          allow={allow}
          allowFullScreen
        ></iframe>
      </Modal>
    </div>
  );
};

VideoModal.defaultProps = {
  allow: "fullscreen",
  caption: "",
  height: 360,
  posterImage: "",
  variant: "",
  videoPlayIcon: "",
  videoSource: "",
  width: 640
};

VideoModal.propTypes = {
  allow: PropTypes.string,
  caption: PropTypes.string,
  height: PropTypes.number,
  posterImage: PropTypes.string,
  variant: PropTypes.string,
  videoPlayIcon: PropTypes.string,
  videoSource: PropTypes.string,
  width: PropTypes.number
};

export default VideoModal;
