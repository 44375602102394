import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import ArrowCircle from "../../images/icons/Icon-Circle-Arrow-Green.svg";
import ArrowCircleInvert from "../../images/icons/Icon-Circle-Arrow-Green-Invert.svg";

import "./testimonial-callout.css";

/*
  Usage – can be passed quote (the quote itself), author and role (the author's
  job title or current role). The quotation marks are added by default.
  Can be passed the unstyled prop to use without a background, border and box
  shadow.  Defaults to false.  When using in the homepage grid, give it the 
  grid prop to apply slightly different styles and an arrow. Grid testimonials
  also have a link prop.

  <TestimonialCallout 
    unstyled
    grid
    quote="This is a game-changer for the nurse-patient relationship."
    author="Smita Mazumdar"
    role="RN, BSN, CCM, BCBSRI director of care management"
    image="../path/to/images/.." || {NamedImage}
  />

*/

const TestimonialCallout = ({
  quote,
  author,
  unstyled,
  role,
  grid,
  url,
  image,
}) => {
  const [over, setOver] = useState(false);
  return (
    <>
      {!!grid ? (
        <Link
          className="testimonial-callout testimonial-callout--grid"
          to={url}
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
        >
          <figure className="testimonial-callout__content">
            <img className="testimonial-callout__image" src={image} alt="" />
            <blockquote>&ldquo;{quote}&rdquo;</blockquote>
            <figcaption>
              <strong>&mdash; {author}</strong>, {role}
            </figcaption>
          </figure>
          <div className="testimonial-callout__arrow">
            <img src={over ? ArrowCircleInvert : ArrowCircle} alt="arrow" />
          </div>
        </Link>
      ) : (
        <div className={`testimonial-callout${unstyled ? "--unstyled" : ""}`}>
          <figure className="testimonial-callout__content">
            <img className="testimonial-callout__image" src={image} alt="" />
            <blockquote>&ldquo;{quote}&rdquo;</blockquote>
            <figcaption>
              <strong>&mdash; {author}</strong>, {role}
            </figcaption>
          </figure>
        </div>
      )}
    </>
  );
};

TestimonialCallout.defaultProps = {
  quote: "",
  author: "",
  role: "",
  unstyled: false,
  grid: false,
  url: "",
  image: "",
};

TestimonialCallout.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  role: PropTypes.string,
  unstyled: PropTypes.bool,
  grid: PropTypes.bool,
  url: PropTypes.string,
  image: PropTypes.string,
};

export default TestimonialCallout;
